body {
  font-family: monospace;
  background-color: #f9f9f9;
  color: #514c4c;
}

#root {
  display: flex;
  justify-content: center;
}

article {
  width: 90%;
  padding-bottom: 2.5em;
}

.gradient-background {
  font-size: 7em;
  font-family: monospace;
}

.gradient-background {
  background: linear-gradient(to left, #81f4e1, #56cbf9);
  color: #fff;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}

.huge-text {
  font-size: 2em;
  font-weight: bold;
}

p {
  font-size: 1.5em;
  line-height: 2;
}

ul {
  list-style: none;
  padding-left: 0;
}

ul li {
  margin: 0.5em;
}

a {
  color: #514c4c;
  padding: 0.3em;
  border-bottom: 2px solid #514c4c;
  text-decoration: none;
}

a:hover {
  background: linear-gradient(to left, #81f4e1, #56cbf9);
  color: #fff;
  display: inline;

  border: 0;
  font-weight: bold;

  /* Needs prefixing */
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}


@media only screen and (max-width: 768px) {
  .gradient-background {
    font-size: 3em;
  }

  .huge-text {
    font-size: 2em;
  }

  p {
    font-size: 1em;
  }

  a {
    color: #fff;
    font-weight: bold;
    background: linear-gradient(to left, #81f4e1, #56cbf9);
    border: 0;
  }
}